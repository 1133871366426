import React from "react";
import { Global, css } from "@emotion/core"
import styled from "@emotion/styled"
import "../styles/styles.scss"

import Nav from "./Nav";

import gridbg from "../images/grid.svg"

const Wrapper = styled("div")`
    
    background: url(${gridbg});
    background-size: cover; 
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;

    padding: 3em 8em;
    margin: 0;

    @media (max-width: 420px){
        padding: 2em 3em;
    }
`;

export default ({ children }) => (
    <Wrapper>
        <Global styles={css`
            div {
                margin: 0;
            }
        `} />
        <Nav />
            {children}
    </Wrapper>
)