import React from "react"
import { css } from "@emotion/core"
import styled from "@emotion/styled"
import { Link } from "gatsby"

import logotypeStatic from "../images/logotype_content.svg"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {faLinkedinIn, faGithub, faTwitter} from "@fortawesome/free-brands-svg-icons"

const Navbar = styled.nav`
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    background-color: $background-color;
    width: 100%;
    @media (max-width: 420px) {
        display: none;
    } 
    
`;
const NavbarR = styled.nav`
    margin: 0;
    padding: 0;
    display: none;
    flex-direction: column;
    background-color: $background-color;
    width: 100%;
    
    @media (max-width: 420px) {
        display: flex;
    } 

`;

const NavbarList = styled.ul`
    margin: 0;
    padding: 0;
    display: flex;
    list-style: none;
    
    @media (max-width: 420px) {
        width: 100%;
        flex-direction: column;
    } 
`;

const NavbarSection = styled.div`
    width: calc(100% / 3);
    display: flex;
    ${props => props.flex && css`
        justify-content: ${props.flex};
    `}
    align-items: center;
    @media (max-width: 420px) {
        width: 100%;
        flex-direction: column;
    } 
`;

const NavbarItem = styled.li`
    display: flex;
    justify-content: center;
    align-items: baseline;

    ${props => props.padding && css`
        padding: ${props.padding};
    `}

    font-family: 'Roboto';
    color: #003399;
    font-weight: 400;
    letter-spacing: 2.33px;
    font-size: 1em;
    text-transform: uppercase;

    a {
        text-decoration: none;
        &:hover {
            color: #F88181;
        }
        &:visted {
            color: #003399;
        }
        &:active{
            color: #003399;
        }

    }

    @media (max-width: 420px) {
        width: 100%;
        justify-content: flex-start;
    } 
`;

const NavbarItemLogo = styled.li`
    display: flex;
    justify-content: center;
    align-items: baseline;

    ${props => props.padding && css`
        padding: ${props.padding};
    `}

    font-family: 'Roboto';
    color: #003399;
    font-weight: 400;
    letter-spacing: 2.33px;
    font-size: 1em;
    text-transform: uppercase;

    a {
        text-decoration: none;
        &:hover {
            color: #F88181;
        }
        &:visted {
            color: #003399;
        }
        &:active{
            color: #003399;
        }

    }

    @media (max-width: 420px) {
        width: 100%;
    } 
`;

const NavbarItemCTA = styled("li")`
    display: flex;
    justify-content: center;
    align-items: basline;

    ${props => props.padding && css`
        padding: ${props.padding};
    `}

    font-family: 'Roboto';
    color: #003399;
    font-weight: 400;
    letter-spacing: 2.33px;
    font-size: 0.8em;
    text-transform: uppercase;

    a {
        padding-bottom: 1vh;
        border-bottom: solid 0.35vh #F88181;
        text-decoration: none;
        &:hover {
            color: #F88181;
            border-bottom: solid 0.35vh #003399;
        }
        &:visited {
            color: #003399;
            border-bottom: solid 0.35vh #F88181;
        }
    }

    @media (max-width: 420px) {
        font-size: 1em;
        width: 100%;
        justify-content: flex-start;
    } 
`;

const NavbarItemIcon = styled.li`
    display: flex;
    justify-content: center;
    align-items: baseline;

    ${props => props.padding && css`
        padding: ${props.padding};
    `}

    font-family: 'Roboto';
    color: #003399;
    font-weight: 400;
    letter-spacing: 2.33px;
    font-size: 1.3em;
    text-transform: uppercase;

    a {
        text-decoration: none;
        &:hover {
            color: #F88181;
        }
        &:visited {
            color: #003399;
        }
    }

    @media (max-width: 420px) {
        width: 100%;
        justify-content: flex-start;
    } 
`;

const ExternalLink = styled.a`
    color: #003399;
    @media (max-width: 420px) {
        text-align: left;
    } 

    &:hover {
        color: #F88181;
    }
`;

export default () => {

    return (
        <div>
            <Navbar>
                <NavbarSection flex="flex-start">
                    <NavbarList>
                        <NavbarItem padding="1vh 2vw 1vh 0vw">
                            <Link to="/" style={{ color: '#003399' }}>
                                home
                            </Link>
                        </NavbarItem>
                       
                        <NavbarItem padding="1vh 2vw">
                            <Link to="/works/" style={{ color: '#003399' }}>
                                my work
                            </Link>
                        </NavbarItem>
                    </NavbarList>
                </NavbarSection>
                <NavbarSection flex="center">
                    <NavbarList>
                        <NavbarItem>
                            <Link to="/">
                                <img src={logotypeStatic} width="148px" alt="Jamie Brannan's Portfolio" />
                            </Link>
                        </NavbarItem>
                    </NavbarList>
                </NavbarSection>
                <NavbarSection flex="flex-end">
                    <NavbarList>
                        <NavbarItemCTA padding="1vh 2vw">
                            <Link to="/contact" style={{ color: '#003399' }} >
                                get in touch
                            </Link>
                        </NavbarItemCTA>
                        <NavbarItemIcon padding="1vh 2vw">
                            <ExternalLink href="https://www.linkedin.com/in/jamie-brannan/" target="_blank">
                                <FontAwesomeIcon icon={faLinkedinIn} color="$primary-blue" />
                            </ExternalLink>
                        </NavbarItemIcon>
                        <NavbarItemIcon padding="1vh 2vw 1vh 0vw">
                            <ExternalLink href="https://github.com/jamie-brannan" target="_blank">
                                <FontAwesomeIcon icon={faGithub} color="$primary-blue" />
                            </ExternalLink>
                        </NavbarItemIcon>
                        <NavbarItemIcon padding="1vh 2vw 1vh 0vw">
                            <ExternalLink href="https://twitter.com/jamiembrannan" target="_blank">
                                <FontAwesomeIcon icon={faTwitter} color="$primary-blue" />
                            </ExternalLink>
                        </NavbarItemIcon>
                    </NavbarList>
                </NavbarSection>
            </Navbar>
            <NavbarR>
                <NavbarSection flex="flex-start">
                    <NavbarList>
                        <NavbarItemLogo>
                            <Link to="/">
                                <img src={logotypeStatic} width="148px" alt="Jamie Brannan's Portfolio" />
                            </Link>
                        </NavbarItemLogo>
                    </NavbarList>
                </NavbarSection>
                <NavbarSection>
                    <NavbarList>
                        <NavbarItem padding="2vh 0vw">
                            <Link to="/" style={{ color: '#003399' }}>
                                home
                            </Link>
                        </NavbarItem>
                        <NavbarItem padding="2vh 0vw">
                            <Link to="/works/" style={{ color: '#003399' }}>
                                my work
                            </Link>
                        </NavbarItem>
                        <NavbarItemCTA padding="2vh 0vw">
                            <Link to="/contact" style={{ color: '#003399' }} >
                                get in touch
                            </Link>
                        </NavbarItemCTA>
                        <NavbarItemIcon padding="2vh 0vw">
                            <ExternalLink href="https://www.linkedin.com/in/jamie-brannan/" target="_blank">
                                <FontAwesomeIcon icon={faLinkedinIn} color="$primary-blue" />
                            </ExternalLink>
                        </NavbarItemIcon>
                        <NavbarItemIcon padding="2vh 0vw">
                            <ExternalLink href="https://github.com/jamie-brannan" target="_blank">
                                <FontAwesomeIcon icon={faGithub} color="$primary-blue" />
                            </ExternalLink>
                        </NavbarItemIcon>
                        <NavbarItemIcon padding="2vh 0vw">
                            <ExternalLink href="https://twitter.com/jamiembrannan" target="_blank">
                                <FontAwesomeIcon icon={faTwitter} color="$primary-blue" />
                            </ExternalLink>
                        </NavbarItemIcon>
                    </NavbarList>
                </NavbarSection>
            </NavbarR>
        </div>
    )

}